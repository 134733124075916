/* RESPONSIBLE TEAM: team-actions */
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  tokenKey: {
    validators: [validator('presence', { presence: true })],
  },
  ttlInSeconds: {
    validators: [validator('number', { allowBlank: true, gte: 0 })],
  },
});
